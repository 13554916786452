import { useState, useEffect } from "react";
import Calendar from '@toast-ui/calendar';
import '@toast-ui/calendar/dist/toastui-calendar.min.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';

import {default as CreateEventPopup} from './CreateEventPopup.js';
import {default as UpdateEventPopup} from './UpdateEventPopup.js';
import {default as Header} from './Header.js';
import { BASE_URL } from '../../settings.js';

let popupData = {};

const boardEvents = [
  /*{
    id: 'event1',
    calendarId: 'cal2',
    title: 'Weekly meeting',
    start: '2024-08-07T09:00:00',
    end: '2024-08-07T10:00:00',
  },
  {
    id: 'event2',
    calendarId: 'cal1',
    title: 'Lunch appointment',
    start: '2024-08-08T12:00:00',
    end: '2024-08-08T13:00:00',
  },
  {
    id: 'event3',
    calendarId: 'cal2',
    title: 'Vacation',
    start: '2024-08-08',
    end: '2024-08-10',
    isAllday: true,
    category: 'allday',
  }, */
]

const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

let calendar;

let userId;

function isUniqueGroup(groupList, group) {
  console.log(group);
  return  (groupList.filter((item) => item.id === group.id)).length < 1;
}

const CalendarView = ({ calendarInfo, monday, changeStep }) => {
  const [isCreatePopupOpen, setCreatePopupOpen] = useState(false);
  const [isUpdatePopupOpen, setUpdatePopupOpen] = useState(false);
  const [headerType, setHeaderType] = useState(false);
  const [showedCalendarId, setShowedCalendarId] = useState(calendarInfo.calendar_id);
  const [viewGroups, setViewGroups] = useState([]);

  useEffect(() => {
    const container = document.getElementById('main-calendar-view');
    const options = {
      defaultView: 'month',
      useFormPopup: false,
      useDetailPopup: false,
      week: {
        taskView: false,
        hourStart: 6,
      },
      gridSelection: {
        enableDblClick: false,
      },
      timezone: {
        zones: [
          {
            timezoteName: 'Asia/Tel_Aviv',
            displayLabel: 'Tel_Aviv',
          },
        ],
      },
      
    };


    if(calendar){
      calendar.destroy();
    }
    calendar = new Calendar(container, options);
    console.log(calendar)
    
    //calendar.createEvents(boardEvents);
    function getUser(userId) {
      fetch(BASE_URL + "/get-user/", {
        method: "post",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        //make sure to serialize your JSON body
        body: JSON.stringify({
          userId: userId,
          calendarId: calendarInfo.calendar_id
        })
      })
      .then(res => res.json())
      .then(
        (results) => {
          console.log(results);

          const groups = [];
          let isReadOnly = true;
          results.users.map((user) => {
            if(user.group.constrain === 'Create') {
              isReadOnly = false;
            } 
          })

          console.log('Is Read Only: ', isReadOnly);
        
          calendar.setOptions({
            isReadOnly: isReadOnly
          })

        },
        (error) => {
          console.log(error)
        }
      
      );
    }

    function getEvents(groups) {
      const groupsIds = groups.map((group) => group.id);
      console.log(groupsIds);
      fetch(BASE_URL + "/get-events/", {
            method: "post",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            //make sure to serialize your JSON body
            body: JSON.stringify({
              calendarId: calendarInfo.calendar_id,
              //userId: userId,
              groupsIds: groupsIds
            })
          })
          .then(res => res.json())
          .then(
            (results) => {
              console.log(results);
              
              boardEvents.length = 0;
              results.result.map((result) => {
                boardEvents.push({
                  id: result.id,
                  calendarId: result.group_id + '',
                  title: result.title,
                  start: result.start,
                  end: result.end,
                  raw:{
                    comment: result.comment
                  }
                });

              })
              
             calendar.clear(); 
             calendar.createEvents(boardEvents)
             calendar.clearGridSelections();

              setCreatePopupOpen(false);
              console.log('NEW CALENDAR')
            },
            (error) => {
              console.log(error);
            }
      );
    }
    


    function getGroups(userId){
      fetch(BASE_URL + "/get-groups/", {
        method: "post",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        //make sure to serialize your JSON body
        body: JSON.stringify({
          calendarId: showedCalendarId,
          userId: userId,
        })
      })
      .then(res => res.json())
      .then(
        (results) => {
          console.log(results);

          setViewGroups((prev) => {
            return [
              ...results.groups.map((group) => {
                  return {
                    id: group.id + '',
                    name: group.name,
                    backgroundColor: group.colorCode,
                  }
                })
            ]
          })
          
          calendar.setCalendars(viewGroups);


          console.log('CALENDAR INFO:', calendarInfo.calendar_id);

      });
    }


    function getParticipants(userId){
      fetch(BASE_URL + "/get-participants/", {
        method: "post",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        //make sure to serialize your JSON body
        body: JSON.stringify({
          calendarId: showedCalendarId,
          userId: userId,
        })
      })
      .then(res => res.json())
      .then(
        (results) => {
          console.log(results);

           setViewGroups((prev) => {

            calendar.setCalendars([
              ...results.participants.map((participant) => {
                  return {
                    id: participant.group.id + '',
                    name: participant.group.name,
                    backgroundColor: participant.group.color_code,
                  }
                })
            ]);
            return [
              ...results.participants.map((participant) => {
                  return {
                    id: participant.group.id + '',
                    name: participant.group.name,
                    backgroundColor: participant.group.color_code,
                  }
                })
            ]
          }) 

          console.log(viewGroups);
          //calendar.setCalendars(viewGroups);
          getEvents(results.participants.map((participant) => participant.group));          
          


          console.log('CALENDAR INFO:', calendarInfo.calendar_id);

      });
    }

    monday.get("context").then((res) => {
        console.log(res);
        userId = res.data.user.id;
        getParticipants(userId);
        //getUser(userId);
        //getGroups(userId);
      },     
      (error) => {
        console.log(error);
      }
    );
    

    
    calendar.on({
    'clickEvent': function(e) {
        console.log('clickSchedule', e.event);
        popupData = e.event
        setUpdatePopupOpen((prevState) => !prevState);
    },
      'selectDateTime': function(e) {
        console.log(e)
        popupData = e;
        setCreatePopupOpen((prevState) => !prevState);
      },
      'createEvent': function(e) {
        console.log('createEvents', e);
      },
    'beforeCreateEvent': function(e) {
        console.log('beforeCreateSchedule', e);
        console.log("HELLOOOOOOO");
        // open a creation popup

        // If you dont' want to show any popup, just use `e.guide.clearGuideElement()`

        // then close guide element(blue box from dragging or clicking days)
        //e.guide.clearGuideElement();
    },
    'beforeUpdateEvent': function(e) {
        console.log('beforeUpdateSchedule', e);
        e.schedule.start = e.start;
        e.schedule.end = e.end;
        calendar.updateSchedule(e.schedule.id, e.schedule.calendarId, e.schedule);
    },
    'beforeDeleteEvent': function(e) {
        console.log('beforeDeleteSchedule', e);
        calendar.deleteSchedule(e.schedule.id, e.schedule.calendarId);
    }
});
      console.log(calendar);
      setHeaderType('week');
    

  }, [showedCalendarId])
  return (
    <>
      { showedCalendarId && 
        
         headerType &&
          <Header 
            calendar={calendar} 
            headerType={headerType}
            setHeaderType={setHeaderType}
            monthNames={monthNames}
            monday={monday}
            calendarInfo={calendarInfo}
            changeStep={changeStep}
            changeShowedCalendarId={setShowedCalendarId}
            showedCalendarId={showedCalendarId}
            viewGroups={viewGroups}
          />
        }
        <div id="main-calendar-view"  style={{'width': '100%', 'height': '100%'}}>
      
        </div>
        { showedCalendarId && (isUpdatePopupOpen || isCreatePopupOpen) &&
          <div 
            style={{'position': 'absolute', 'width': '100vw', 'height': '100vh', 'zIndex': 1000}}
            onClick={(e) => {
              if(e.target.closest('.add-event-container')) return;
              if(isUpdatePopupOpen){ 
                calendar.clearGridSelections();
                setUpdatePopupOpen(false);
              }
              if(isCreatePopupOpen) {
                calendar.clearGridSelections();
                setCreatePopupOpen(false);
              }
            }}
          >
            {isUpdatePopupOpen && 
              <UpdateEventPopup 
                calendarInfo={calendarInfo} 
                monday={monday} 
                data={popupData}
                setUpdatePopupOpen={setUpdatePopupOpen} 
                boardEvents={boardEvents}
                calendar={ calendar }
                groups={viewGroups}
              />}
            {isCreatePopupOpen && 
              <CreateEventPopup 
                setCreatePopupOpen={setCreatePopupOpen} 
                calendarInfo={calendarInfo} 
                monday={monday} 
                data={popupData}
                boardEvents={boardEvents}
                calendar={ calendar }
                userId={userId}
                groups={viewGroups}
              />}
          </div>
        

      }
    </>
  )
}


export default CalendarView;
