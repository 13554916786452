import React, { useState, useEffect } from 'react';
import { Listbox } from '@headlessui/react';
import { BASE_URL } from '../settings.js';
//import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';

const colors = [
  { name: 'Red', colorCode: '#ef4444' },
  //{ name: 'Red', colorCode: 'rgb(239 68 68 / 1)' },
  { name: 'Orange', colorCode: '#f97316'},
  //{ name: 'Orange', colorCode: 'rgb(249 115 22 / 1)'},
  { name: 'Green', colorCode: '#22c55e'},
  //{ name: 'Green', colorCode: 'rgb(34 197 94 / 1)'},
  { name: 'Blue', colorCode: '#3b82f6'},
  //{ name: 'Blue', colorCode: 'rgb(59 130 246 / 1)'},
  { name: 'Purpule', colorCode: '#a855f7'},
  //{ name: 'Purpule', colorCode: 'rgb(168 85 247 / 1)'},
];

const groups = [
  { name: 'Group name 1', 
    color: { name: 'Red', colorCode: '#ef4444' },
    description: '',
  }
];


function AddCalendar({ calendarInfo, changeStep}) {
  const [selectedColor, setSelectedColor] = useState(colors[0]);
  const [groupList, setGroupList] = useState(groups);
  //const [selectedConstrains, setSelectedConstrains] = useState(constrains[0]);

  useEffect(() => {
    console.log(calendarInfo)
  }, [])

  function continueHandler() {
    console.log(groupList);
    fetch(BASE_URL + "/create-groups", {
        method: "post",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        //make sure to serialize your JSON body
        body: JSON.stringify({
          calendarId: calendarInfo.calendar_id, 
          groups: groupList,
        })
      })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
          changeStep((prev) => prev + 1)
        },
        // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
        // чтобы не перехватывать исключения из ошибок в самих компонентах.
        (error) => {
          console.log(error);
        }
      ) 
  }


  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-8">
        <nav className="flex items-center space-x-4 text-gray-500 text-sm">
          <a href="#" className="hover:underline">Home</a>
          <span>/</span>
          <a href="#" className="hover:underline">Calendar</a>
          <span>/</span>
          <span className="text-gray-800">Add Calendar</span>
        </nav>
        <h1 className="text-3xl font-bold mt-4">Add Calendar</h1>
        <div className="mt-8">
          <div className="flex items-center space-x-8 mb-8">
            <div className="text-center text-gray-400">
              <div className="text-lg font-semibold">1</div>
              <div className="text-sm">General Info</div>
            </div>
            <div className="text-center">
              <div className="text-lg font-semibold text-purple-500">2</div>
              <div className="text-sm">Groups</div>
            </div>
            <div className="text-center text-gray-400">
              <div className="text-lg font-semibold">3</div>
              <div className="text-sm">Users</div>
            </div>
            <div className="text-center text-gray-400">
              <div className="text-lg font-semibold">4</div>
              <div className="text-sm">Automation</div>
            </div>
          </div>
          <div className="mb-8">
            <h2 className="text-xl font-bold mb-4">Groups</h2>
            <div className="overflow-x-auto">
              <table className="min-w-full bg-white">
                <thead>
                  <tr className="w-full bg-gray-100">
                    <th 
                      className="py-2"
                      style={{'textAlign': 'left', 'paddingLeft': '10px'}}
                    >
                      Color
                    </th>
                    <th 
                      className="py-2"
                      style={{'textAlign': 'left', 'paddingLeft': '10px'}}
                    >
                      Name
                    </th>
                    <th className="py-2">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {groupList.map((group, index) => (
                    <tr key={index} className="border-t">
                      <td className="py-2 px-4 flex items-center">
                        
                        <Listbox value={group.color} onChange={(e) => {
                          setGroupList((prev) => {
                            return  prev.map((item, i) => {
                                      if(i === index) {
                                        item.color = e;
                                      } 
                                      return item;
                                    })
                          })
                          setSelectedColor(group.color)
                        }
                        }>
                          {({ open }) => (
                            <>
                              <Listbox.Button 
                                className="relative w-32 bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring focus:border-blue-300"
                                style={{'display': 'flex', 'alignItems': 'center'}}
                              >
                                <span 
                                  className={`block w-3 h-3 rounded-full mr-3 ${group.color.colorCode}`} aria-hidden="true" 
                                  style={{'backgroundColor': group.color.colorCode}}
                                />
                                <span 
                                  className="block truncate" 
                                >
                                  {group.color.name}
                                </span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">

                                </span>
                              </Listbox.Button>
                              


                              <Listbox.Options 
                                className="absolute z-10 mt-1 w-45 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">

                                {colors.map((color, index) => (
                                  <Listbox.Option
                                    style={{'display': 'flex', 'alignItems': 'center'}}
                                    key={index}
                                    className={({ active }) =>
                                      `cursor-default select-none relative py-2 pl-3 pr-9 ${
                                        active ? 'text-white bg-indigo-600' : 'text-gray-900'
                                      }`
                                    }
                                    value={color}
                                  >
                                    {({ selected, active }) => (
                                      <>

                                        <span 
                                          className={`block w-3 h-3 rounded-full mr-3 ${color.colorCode}`} aria-hidden="true" 
                                          style={{'backgroundColor': color.colorCode}}
                                        />
                                        <span className={`block truncate ${selected ? 'font-semibold' : 'font-normal'}`}>{color.name}</span>
                                        {selected ? (
                                          <span
                                            className={`absolute inset-y-0 right-0 flex items-center pr-4 ${
                                              active ? 'text-white' : 'text-indigo-600'
                                            }`}
                                          >

                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </>
                          )}
                        </Listbox>
                      </td>

                      <td className="py-2 px-4 flex items-center">
                        <textarea 
                          className="relative w-45 bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring focus:border-blue-300"
                          placeholder="Description"
                          onInput={(e) => group.description = e.target.value}
                        >

                        </textarea>

                        
                        { /*<Listbox value={group.constrain} onChange={(e) => {
                            console.log(e)
                            setGroupList((prev) => {
                              return  prev.map((item, i) => {
                                        if(i === index) {
                                          item.constrain = e;
                                        } 
                                        return item;
                                      })
                            })
                          }
                        }
                        >
                          {({ open }) => (
                            <>
                                                            <Listbox.Button className="relative w-32 bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring focus:border-blue-300">
                                <span className="block truncate">{group.description}</span>
                                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">

                                </span>
                              </Listbox.Button>
                              

                                <Listbox.Options className="absolute z-10 mt-1 w-32 bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                  {constrains.map((constrain, index) => (
                                    <Listbox.Option
                                      key={index}
                                      className={({ active }) =>
                                        `cursor-default select-none relative py-2 pl-3 pr-9 ${
                                          active ? 'text-white bg-indigo-600' : 'text-gray-900'
                                        }`
                                      }
                                      value={constrain}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <span className={`block truncate ${selected ? 'font-semibold' : 'font-normal'}`}>{constrain.name}</span>
                                          {selected ? (
                                            <span
                                              className={`absolute inset-y-0 right-0 flex items-center pr-4 ${
                                                active ? 'text-white' : 'text-indigo-600'
                                              }`}
                                            >

                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                            </>
                          )}
                        </Listbox>
                              */}
                      </td>

                      <td className="py-2 px-4">
                        <input 
                          onInput={(e) => {
                            setGroupList((prev) => {
                            return  prev.map((item, i) => {
                                      if(i === index) {
                                        item.name = e.target.value;
                                      } 
                                      return item;
                                    })
                          }) }} 
                          value={group.name}
                        />

                      </td>
                      <td className="py-2 px-4 text-center">
                        <button className="text-red-600 hover:text-red-800" onClick={() => {
                          const updatedGroups = groupList.filter((_, i) => i !== index);
                          setGroupList(updatedGroups);
                        }}>
                          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                          </svg>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <button className="mt-4 flex items-center text-purple-600 hover:text-purple-800"
              onClick={() => {
                setGroupList((prev) => {
                  return [
                    ...prev,
                    { 
                      name: 'New Group', 
                      color: { 
                        name: 'Red', 
                        colorCode: 'rgb(239 68 68 / 1)'
                      },
                      constrain: { 
                          name: 'View'
                      },
                    }
                  ]
                })
              }
            }
            >
              <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4"></path>
              </svg>
              Add Group
            </button>
          </div>
          <div className="flex justify-between mt-8">
            <button className="py-2 px-4 border border-gray-300 rounded-lg text-gray-600 hover:bg-gray-100">Back</button>
            <button className="py-2 px-4 bg-gray-800 text-white rounded-lg hover:bg-gray-700"
              onClick={continueHandler}
            >Continue</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddCalendar;
