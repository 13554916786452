import React from 'react';
import { useState, useEffect } from 'react';
import DateTimePicker from 'react-datetime-picker';
import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';


import {default as SearchSelect} from './SelectBoards.js';
import { BASE_URL } from '../../settings.js';
import './AddEvent.css';

const AddEvent = ({ calendarInfo, monday, data, calendar, setUpdatePopupOpen, boardEvents, groups}) => {

  const [boards, setBoards] = useState([{name: 'Test Board', id: 0}]);
  const [currentBoard, setCurrentBoard] = useState(0);

  const [boardItems, setBoardItems] = useState([]);
  const [boardItem, setBoardItem] = useState({itemName: '', itemId: ''});

  const [title, setTitle] = useState('')
  const [start, setStart] = useState(data.start.d.d)
  const [end, setEnd] = useState(data.end.d.d)
  const [groupId, setGroupId] = useState(0)
  const [item, setItem] = useState({itemName: '', itemId: 0})


  //const [groups, setGroups] = useState([]);
  const [comment, setComment] = useState('');

  
  //const [showAutomatization, setShowAutomatizations] = useState(false);



  function isUniqueGroup(groupList, group) {
    console.log(group);
    return  (groupList.filter((item) => item.id === group.id)).length < 1;
  }


  function removeEventHandler() {
    fetch(BASE_URL + "/delete-event/", {
        method: "post",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        //make sure to serialize yojur JSON body
        body: JSON.stringify({
          eventId: data.id
        })
      })
      .then(res => res.json())
      .then(
        (results) => {
          console.log(results);


          calendar.clear(); 
          boardEvents = boardEvents.filter((event) => {
            return event.id != data.id;
          })
          calendar.createEvents(boardEvents);
          console.log(boardEvents);


          calendar.clearGridSelections();
          setUpdatePopupOpen(false);

        
        },
        // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
        // чтобы не перехватывать исключения из ошибок в самих компонентах.
        (error) => {
          console.log(error);
        }
      );
  }


  function getBoardItems(value, cursor, tempBoardItems) {
    let query;
    if(cursor) {
      query = `query { boards (ids: ${value}){ items_page (limit: 500, cursor: "${cursor}"){ cursor items { id name creator_id state} }} }`;
    } else {
      query = `query { boards (ids: ${value}){ items_page (limit: 500){ cursor items { id name creator_id state} }} }`;
    }
    monday.api(query).then((res) => {
      console.log(res); 
      if(res) {
        if(res.data.boards[0].items_page.cursor === null){

          setBoardItems(() => {
            return [
              ...tempBoardItems,
              ...res.data.boards[0].items_page.items.map((item) => {
                    return {
                      name: item.name,
                      id: item.id,
                      value: item.id,
                      //groups: item.groups
                    }
              })
            ]
          })
          tempBoardItems.length = 0;
        } else {
          cursor = res.data.boards[0].items_page.cursor;
          tempBoardItems.push(...res.data.boards[0].items_page.items.map((item) => {
                    return {
                      name: item.name,
                      id: item.id,
                      value: item.id,
                      //groups: item.groups
                    }
              }))
          getBoardItems(value, cursor, tempBoardItems);
        }
        
      }
    })
  }


  function changeBoardHandler(value) {
    //setGroupId(e.target.value);
    const tempBoardItems = []
    getBoardItems(value, null, tempBoardItems);
  }

  function changeItemsHandler(value) {
    setItem(() => {
      return {
        itemId: value,
        itemName: boardItems.filter((item) => item.id == value)[0].name
      }
    })
  }


  function updateEventHandler(e) {
    console.log(currentBoard, item)
    fetch(BASE_URL + "/update-event/", {
        method: "post",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        //make sure to serialize yojur JSON body
        body: JSON.stringify({
          eventId: data.id,
          start: start,
          end: end,
          title: title,
          comment: comment,
          groupId: groupId, 
          boardIds: [currentBoard + ''],
          itemIds: [item.itemId]
        })
    /*start: str 
    end: str
    title: str
    comment: str
    groupId: int 
    boardIds: list[str]
    itemIds: list[str] */
      })
      .then(res => res.json())
      .then(
        (results) => {
          console.log(results);

            /*boardEvents.push({
              id: results.result.id,
              calendarId: results.result.group_id,
              title: results.result.title,
              start: results.result.start,
              end: results.result.end,
              body: results.result.comment,
              row:{
                comment: results.result.comment
              },
              comment: results.result.comment,
            });
            */ 
         calendar.clear(); 
          calendar.createEvents(boardEvents)
          console.log(boardEvents);


          calendar.clearGridSelections();
          setUpdatePopupOpen(false);
           
        /*{
          id: 'event1',
          calendarId: 'cal2',
          title: 'Weekly meeting',
          start: '2024-08-07T09:00:00',
          end: '2024-08-07T10:00:00',
        }*/

        },
        // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
        // чтобы не перехватывать исключения из ошибок в самих компонентах.
        (error) => {
          console.log(error);
        }
      );

    calendar.clearGridSelections();
    setUpdatePopupOpen(false);
  }

  function changeItemsHandler(value) {
    setItem(() => {
      return {
        itemId: value,
        itemName: boardItems.filter((item) => item.id == value)[0].name
      }
    })
  }

  useEffect(() => {
    console.log(data);

    fetch(BASE_URL + "/get-event/", {
        method: "post",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        //make sure to serialize yojur JSON body
        body: JSON.stringify({
          eventId: data.id
        })
      })
      .then(res => res.json())
      .then(
        (results) => {
          console.log(results);


          setTitle(results.result.title);
          setStart(results.result.start);
          setEnd(results.result.end);
          setGroupId(results.result.group_id);
          setItem({'itemName': results.result.item_name, 'itemId': results.result.item_id});
          setComment(results.result.comment);
          setCurrentBoard((results.result.board_items && results.result.board_items.length ? results.result.board_items[results.result.board_items.length-1].board_id : 0));
          
          monday.api(`query { boards (state: active, limit: 100){ name id state type groups{title id} }}`).then((res) => {
            console.log(res); 
            if(res) {
              const newBoards = res.data.boards.filter((item) => item.type === 'board' && item.state === 'active');
              console.log('new boards:', newBoards);
              setBoards(() => {
                return [
                  ...newBoards.map((item) => {
                        return {
                          name: item.name,
                          id: item.id,
                          value: item.id
                          //groups: item.groups
                      }
                    
                  })
                ]
                
              })
            }
          })
        if(results.result.board_items.length ) {
          const tempBoardItems = []
          getBoardItems(results.result.board_items[0].board_id, null, tempBoardItems)
          setBoardItem({'board_id': (results.result.board_items.length ? results.result.board_items[results.result.board_items.length-1].item_id : 0)});

          }
          
        },
        // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
        // чтобы не перехватывать исключения из ошибок в самих компонентах.
        (error) => {
          console.log(error);
        }
      );

  }, []);

  useEffect(() => {
    setBoardItem((prev) => {
        console.log(prev)
        return prev ? prev.board_id : 0;
      }
    );
  }, [boardItems])

  return (
    <div 
      className="add-event-container" 
      style={{'position': 'absolute', 'left': '50%', 'transform': 'translateX(-50%)', 'zIndex': 1001}}
    >
      <div
        style={{'display': 'flex', 'justifyContent': 'space-around', 'alignItems': 'center', 'marginBottom': '20px'}}
      >
        <h2 style={{'marginBottom': 0}}>Update Event</h2>
        <button 
            className="text-gray-500 removeCalendar"
            onClick={() => { removeEventHandler() }}
        >
            🗑
        </button>
      </div>
      
      <input 
        type="text" 
        placeholder="Name" 
        className="input-field"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
    />
      
      <div className="">
        <select 
          className="input-field"
          onChange={(e) => { 
            console.log('groupId: ', e.target.value)
            return setGroupId(e.target.value)
          }}
          value={groupId ? groupId : 0}
        >
          <option value={0} disabled selected>Group</option>
           {
          groups.map((group) => {
              console.log(group);
              if(group.id == groupId) {
                return (
                  <option  selected={true} value={group.id}>{group.name}</option>
                )
              } else {
                return (
                  <option value={group.id}>{group.name}</option>
                )
              }
            })
          }
        </select>
      </div>
      {
        /*
        <div className="">
          <select className="input-field">
            <option value="" disabled selected>Select board</option>
          </select>
        </div>
        
        <div className="">
          <select className="input-field">
            <option value="" disabled selected>Select item</option>
          </select>
        </div>
          */
      } 
      

      <div className="">
        <SearchSelect 
          options={boards}
          placeholder={'Check board'}
          changeData={changeBoardHandler}
          setCurrentValue={setCurrentBoard}
          defaultValue={currentBoard}
        />
        
      </div>

      <div className="">
        <SearchSelect 
          options={boardItems}
          placeholder={'Check board item'}
          changeData={changeItemsHandler}
          setCurrentValue={setBoardItem}
          defaultValue={boardItem}
        />
      </div>


      <div 
        className="time-input"
        style={{'flex-direction': 'column'}}
      >
        <DateTimePicker 
          style={{'maxWidth': '50%'}}
          className=""
          onChange={setStart}
          value={start}
        />
        
        <span>:</span>
        <DateTimePicker 
          style={{'maxWidth': '50%'}}
          className=""
          onChange={setEnd}
          value={end}
        />
      </div>
      
      
      <textarea 
        placeholder="Comment" 
        className="input-field textarea"
        onChange={(e) => setComment(e.target.value) }
        value={comment}
      >
        {comment}
      </textarea>
      
      <div className="toggle-container">
        <label className="switch">
          <input 
            type="checkbox" 
            onChange={() => {}/*setShowAutomatizations((prev) => !prev)*/}
          />
          <span className="slider round"></span>
        </label>
        <span>Add one-time automation</span>
      </div>
     {
        //showAutomatizations &&
          <div className="automation-options">
            <div className="automation-row">
              <span>When</span>
              <span>Date</span>
            </div>
            <div className="automation-row">
              <select className="small-select">
                <option>Group entry</option>
              </select>
              <select className="small-select">
                <option>created</option>
              </select>
            </div>
            <div className="automation-row">
              <select className="small-select">
                <option>send</option>
              </select>
              <select className="small-select">
                <option>letter</option>
              </select>
            </div>
            <div className="automation-row">
              <select className="small-select">
                <option>to user</option>
              </select>
              <select className="user-select">
                <option>User</option>
              </select>
            </div>
          </div>

     } 
      
      
      <button 
        className="create-button"
        onClick={updateEventHandler}
      >Update Event</button>
    </div>
  );
}

export default AddEvent;
