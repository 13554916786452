const BASE_URL = 'https://calendar.univers-transit.com';
//const BASE_URL = 'http://127.0.0.1:8000';


//steps
const CONNECT_STEP = 0;
const CREATECALENDAR_STEP = 1;
const ADDGROUP_STEP = 2;
const ADDUSER_STEP = 3;
const ADDAUTOMATIZATION_STEP = 4;
const SHOWCALENDAR_STEP = 5



export {BASE_URL, CREATECALENDAR_STEP, CONNECT_STEP, ADDGROUP_STEP, ADDUSER_STEP, ADDAUTOMATIZATION_STEP, SHOWCALENDAR_STEP};
