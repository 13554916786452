import { useState, useRef, useEffect } from 'react';
import { Listbox } from '@headlessui/react';
import {BASE_URL} from '../settings.js';
//import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';

const colors = [
  { name: 'Red', colorCode: 'bg-red-500' },
  { name: 'Orange', colorCode: 'bg-orange-500' },
  { name: 'Green', colorCode: 'bg-green-500' },
  { name: 'Blue', colorCode: 'bg-blue-500' },
  { name: 'Purple', colorCode: 'bg-purple-500' },
];

function App({calendarInfo, monday, changeStep}) {
  const [selectedColor, setSelectedColor] = useState(colors[1]);
  const [calendarName, setCalendarName] = useState('My service');

  function continueHandler() {
    monday.get("context").then(res =>{ 
      console.log(res)
      

      fetch(BASE_URL + "/create-calendar", {
        method: "post",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        //make sure to serialize your JSON body
        body: JSON.stringify({
          accountId: res ? res.data.account.id : '0', 
          title: calendarName,
          colorName: selectedColor.name,
          colorCode: selectedColor.colorCode,
        })
      })
      .then(res => res.json())
      .then(
        (result) => {
          console.log(result)
          calendarInfo.calendar_id = result.calendar_id
          changeStep((prev) => prev + 1)
        },
        // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
        // чтобы не перехватывать исключения из ошибок в самих компонентах.
        (error) => {
          console.log(error);
        }
      )
    
    })

    

  }
  

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <div className="max-w-4xl mx-auto bg-white shadow-lg rounded-lg p-8">
        <nav className="flex items-center space-x-4 text-gray-500 text-sm">
          <a href="#" className="hover:underline">Home</a>
          <span>/</span>
          <a href="#" className="hover:underline">Calendar</a>
          <span>/</span>
          <span className="text-gray-800">Add Calendar</span>
        </nav>
        <h1 className="text-3xl font-bold mt-4">Add Calendar</h1>
        <div className="mt-8">
          <div className="flex items-center space-x-8 mb-8">
            <div className="text-center">
              <div className="text-lg font-semibold">1</div>
              <div className="text-sm">General Info</div>
            </div>
            <div className="text-center text-gray-400">
              <div className="text-lg font-semibold">2</div>
              <div className="text-sm">Groups</div>
            </div>
            <div className="text-center text-gray-400">
              <div className="text-lg font-semibold">3</div>
              <div className="text-sm">Users</div>
            </div>
            <div className="text-center text-gray-400">
              <div className="text-lg font-semibold">4</div>
              <div className="text-sm">Automation</div>
            </div>
          </div>
          <div className="mb-8">
            <h2 className="text-xl font-bold mb-4">General Info</h2>
            <label className="block mb-4">
              <span className="block text-sm font-medium text-gray-700">Name</span>
              <input
                type="text"
                className="mt-1 block w-full px-3 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring focus:border-blue-300"
                defaultValue="My Service #1"
                value={calendarName}
                onInput={(e) => {setCalendarName(e.target.value)}}
              />
            </label>
            <Listbox value={selectedColor} onChange={setSelectedColor}>
              {({ open }) => (
                <>
                  <Listbox.Label className="block text-sm font-medium text-gray-700">Color</Listbox.Label>
                  <div className="relative mt-1">
                    <Listbox.Button className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring focus:border-blue-300">
                      <span className="flex items-center">
                        <span className={`block w-3 h-3 rounded-full mr-3 ${selectedColor.colorCode}`} aria-hidden="true" />
                        <span className="block truncate">{selectedColor.name}</span>
                      </span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        
                      </span>
                    </Listbox.Button>
                    <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                      {colors.map((color, index) => (
                        <Listbox.Option
                          key={index}
                          className={({ active }) =>
                            `cursor-default select-none relative py-2 pl-3 pr-9 ${
                              active ? 'text-white bg-indigo-600' : 'text-gray-900'
                            }`
                          }
                          value={color}
                        >
                          {({ selected, active }) => (
                            <>
                              <div className="flex items-center">
                                <span className={`block w-3 h-3 rounded-full mr-3 ${color.colorCode}`} aria-hidden="true" />
                                <span className={`block truncate ${selected ? 'font-semibold' : 'font-normal'}`}>{color.name}</span>
                              </div>
                              {selected ? (
                                <span
                                  className={`absolute inset-y-0 right-0 flex items-center pr-4 ${
                                    active ? 'text-white' : 'text-indigo-600'
                                  }`}
                                >

                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </div>
                </>
              )}
            </Listbox>
          </div>
          <div className="text-right">
            <button 
              className="bg-gray-800 text-white px-4 py-2 rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              onClick={continueHandler}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
