
const AutomatizationItem = ({setAutomatizationsList, automazationsList, index}) => {
    
  function removeButtonHandle(itemIndex) {
    setAutomatizationsList((prev) => {
      return prev.filter((item, index) => {
        return index !== itemIndex;
      })
    })
  }
  return (
        <div key={index} className="flex items-center bg-white shadow rounded-lg p-4 mb-4">
          <div className="flex-1 grid grid-cols-6 gap-4">
            <div>When</div>
            <div>Date</div>
            <select className="border rounded px-2">
              <option>Group entry</option>
            </select>
            <select className="border rounded px-2">
              <option>{'created'}</option>
              <option>{'edited'}</option>
            </select>
            <select className="border rounded px-2">
              <option>{'create'}</option>
              <option>{'send'}</option>
            </select>
            <select className="border rounded px-2">
              <option>{'task'}</option>
              <option>{'letter'}</option>
            </select>
          </div>
          <div className="ml-4">
            {index < 3 ? 'to email' : 'for user'}
          </div>
          <input 
            type="text" 
            className="ml-4 border rounded px-2 py-1" 
            placeholder={index < 3 ? 'Email' : 'Choose user'}
          />
          <button className="ml-4 text-gray-400" onClick={() => removeButtonHandle(index)}>🗑</button>
        </div>
      )
}

export default AutomatizationItem;
