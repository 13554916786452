import SelectSearch from 'react-select-search';
import 'react-select-search/style.css'
import { useState, useEffect } from 'react';

const CustomSelect = ({ options, placeholder, changeData, setCurrentValue, defaultValue }) => {
  const [value, setValue] = useState(0);

  
  function changeHandler(value) {
    console.log(value);
    setValue(value);  
    changeData(value);
    setCurrentValue(value);
  }

  useEffect(() => {
    if(defaultValue) {
      setValue(() => {
        const values = options.filter((option) => option.value == defaultValue);
        if(values.length) {
          console.log(values)
          return values[0];
        }
      });
    }
  }, []);

  return (
     <SelectSearch
        options={options}
        onChange={changeHandler} 
        value={value}
        search={true}
        placeholder={placeholder}
        renderValue={(valueProps) => 
        <input 
          className='input-field'
          {...valueProps} 
        /> }
     /> 
  );
};

export default CustomSelect;
